import { FC } from "react";
import BaseModal from "../../shared/modals/BaseModal";
import CommonError from "../../shared/form-inputs/CommonError";
import CustomInput from "../../shared/form-inputs/CustomInput";
import { PayPalCheckoutButton } from "../../../integrations/paypal/components/PayPalCheckoutButton";
import { FormikProps } from "formik";
import { DonateDTO, DonateForm } from "../../../domain/models/dto/DonateDTO";
import { useTranslation } from "react-i18next";
import { payPalServiceFactory } from "../../../integrations/paypal/PayPalServiceImpl";
import { useErrorHandler } from "../../../custom-hooks/base-hooks/useErrorHandler";
import { StatusCodes } from "http-status-codes";
import { PayPalPayment } from "../../../integrations/paypal/domain/PayPalPayment";
import { NumericFormat } from "react-number-format";
import { donateFormFields } from "../../../validation-schemas/DonateFormSchema";

interface DonatePaypalModalProps {
    isVisible: boolean;
    form: FormikProps<DonateForm>;
    setVisibility: (value: boolean) => void;
    setSuccessModalVisibility: (value: boolean) => void;
    buttonElementClassName?: string;
}

const paypalService = payPalServiceFactory();

const DonatePaypalModal: FC<DonatePaypalModalProps> = ({
    isVisible,
    form,
    setVisibility,
    setSuccessModalVisibility,
    buttonElementClassName,
}) => {
    const { errorKey, setErrorKey } = useErrorHandler(StatusCodes.INTERNAL_SERVER_ERROR);
    const { t } = useTranslation();
    const formInvalid = !!form.errors.clientEmail || !!form.errors.value;
    const paymentHandler = (paymentResult: PayPalPayment) => {
        if (!paymentResult.isApproved) {
            return setErrorKey("errors.common");
        }
        setVisibility(false);
        form.resetForm();
        setSuccessModalVisibility(true);
    };

    return (
        <BaseModal
            isVisible={isVisible}
            setIsVisible={setVisibility}
            title={t("donateForm.supportTitle")}
            closingCrossClassName="cursor-pointer w-5 h-5"
            confirmHandler={form.handleSubmit}
            closeHandler={() => form.resetForm()}
            additionalTitleClassName="font-manrope !text-brown3 !text-[24px] text-center px-4"
            additionalWrapClassName="max-h-[90vh]"
            additionalChildWrapClassName="!bg-beige4 p-6"
            buttonElement={
                <div
                    onClick={() => {
                        setVisibility(true);
                    }}
                    className={`flex items-center justify-center bg-button-gradient w-[174px] h-[37px] font-semibold py-[5px] rounded-full ml-[50px] cursor-pointer ${buttonElementClassName}`}
                >
                    <div className="flex justify-center items-center">
                        <p className="font-cormorant leading-[24.6px] text-[17px] bg-clip-text text-[transparent] bg-button-text-gradient mb-[3px]">
                            {t("donateForm.actionCallTitle")}
                        </p>
                    </div>
                </div>
            }
            customConfirmButton={
                <PayPalCheckoutButton
                    value={form.values.value}
                    data={{ clientEmail: form.values.clientEmail } as DonateDTO}
                    isValidData={form.isValid && form.dirty}
                    setCommonPaymentError={() => setErrorKey("errors.common")}
                    executeServiceMethod={paypalService.executeDonatePayment.bind(paypalService)}
                    paymentHandler={paymentHandler}
                ></PayPalCheckoutButton>
            }
        >
            <CustomInput
                labelKey="donateForm.clientEmail"
                labelClassName="font-manrope !font-normal text-[22px] leading-[32px] text-brown3 mb-1"
                type="text"
                name="clientEmail"
                value={form.values.clientEmail}
                onChange={form.handleChange}
                wrapClassName="w-full"
                className="!bg-beige4 text-[20px] text-center font-manrope !text-brown3 border-l-0 border-t-0 border-r-0 border-b rounded-none !h-[40px] w-[360px] border-brown3 shadow-none focus:outline-none focus:border-l-transparent focus:border-t-transparent focus:border-r-transparent focus:border-b-brown3 focus:ring-0 mb-[32px]"
            />
            <div className="flex flex-col justify-center">
                <label
                    className="font-manrope !font-normal text-[22px] leading-[32px] text-brown3 mb-1"
                    htmlFor={t("donateForm.value")}
                >
                    {t("donateForm.value")}
                </label>
                <NumericFormat
                    name="value"
                    isAllowed={({ floatValue }) => !floatValue || floatValue <= donateFormFields.value.max}
                    decimalScale={2}
                    type="text"
                    className="!bg-beige4 text-[20px] text-center font-manrope !text-brown3 border-l-0 border-t-0 border-r-0 border-b rounded-none !h-[40px] w-[360px] border-brown3 shadow-none focus:outline-none focus:border-l-transparent focus:border-t-transparent focus:border-r-transparent focus:border-b-brown3 focus:ring-0 mb-[32px]"
                    suffix=" €"
                    value={form.values.value || ""}
                    onValueChange={(values) => {
                        const { floatValue } = values;
                        form.setFieldValue("value", floatValue !== undefined ? floatValue : "");
                    }}
                />
            </div>
            <CommonError form={form} errorKey={formInvalid || errorKey ? errorKey : ""} />
        </BaseModal>
    );
};

export default DonatePaypalModal;
