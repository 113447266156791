import { FC, ReactNode } from "react";
import Popup from "reactjs-popup";
import ClosingCrossSVG from "../svg/ClosingCrossSVG";
import useBodyFixed from "../../../custom-hooks/base-hooks/useBodyFixed";

interface BaseModalProps {
    children: ReactNode;
    buttonElement?: ReactNode;
    customConfirmButton?: ReactNode;
    title: string;
    confirmTitle?: string;
    cancelTitle?: string;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    confirmHandler: () => void;
    closeHandler?: () => void;
    onOpen?: () => void;
    additionalWrapClassName?: string;
    additionalChildWrapClassName?: string;
    additionalButtonClassName?: string;
    additionalConfirmButtonClassName?: string;
    additionalTitleClassName?: string;
    disableCancelButton?: boolean;
    closingCrossClassName?: string;
}

const BaseModal: FC<BaseModalProps> = (props) => {
    const { fixed, unfixed } = useBodyFixed();
    const openHandler = () => {
        props.onOpen && props.onOpen();
        fixed();
    };
    const closeHandler = () => {
        props.setIsVisible(false);
        props.closeHandler && props.closeHandler();
        unfixed();
    };

    return (
        <>
            {props.buttonElement || (
                <button
                    data-modal-target="static-modal"
                    data-modal-toggle="static-modal"
                    className={`text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center max-h-10 ${props.additionalButtonClassName}`}
                    type="button"
                    onClick={() => props.setIsVisible(true)}
                >
                    {props.title}
                </button>
            )}
            <Popup
                onOpen={openHandler}
                open={props.isVisible}
                modal
                overlayStyle={{ zIndex: 99999, backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                onClose={closeHandler}
            >
                <div
                    id="static-modal"
                    data-modal-backdrop="static"
                    className={`overflow-y-auto overflow-x-hidden top-0 right-0 left-0 justify-center items-center h-[calc(100%-1rem)] ${props.additionalWrapClassName}`}
                >
                    <div className="relative px-4 w-full max-h-full">
                        {/* <!-- Modal content --> */}
                        <div className={`relative bg-white rounded-lg shadow ${props.additionalChildWrapClassName}`}>
                            {/* <!-- Modal header --> */}
                            <div className="flex items-center justify-between p-4 rounded-t">
                                <h3 className={`text-xl font-semibold text-gray-900 ${props.additionalTitleClassName}`}>
                                    {props.title}
                                </h3>
                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                                    data-modal-hide="static-modal"
                                    onClick={closeHandler}
                                >
                                    <ClosingCrossSVG className={props.closingCrossClassName} stroke="#6A4F3E" />
                                </button>
                            </div>
                            {/* <!-- Modal body --> */}
                            <div className="p-4 max-h-[75vh] overflow-auto">{props.children}</div>
                            {/* <!-- Modal footer --> */}
                            {props.customConfirmButton ? (
                                props.customConfirmButton
                            ) : (
                                <div className="flex justify-center items-center p-4 rounded-b">
                                    <button
                                        data-modal-hide="static-modal"
                                        type="button"
                                        className={
                                            "w-32 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center" +
                                            (props.additionalConfirmButtonClassName
                                                ? ` ${props.additionalConfirmButtonClassName}`
                                                : "")
                                        }
                                        onClick={props.confirmHandler}
                                    >
                                        {props.confirmTitle}
                                    </button>
                                    {props.disableCancelButton ? (
                                        <></>
                                    ) : (
                                        <button
                                            data-modal-hide="static-modal"
                                            type="button"
                                            className="w-32 py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700"
                                            onClick={closeHandler}
                                        >
                                            {props.cancelTitle}
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Popup>
        </>
    );
};

export default BaseModal;
