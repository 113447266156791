import { apiURL } from "../../Config";
import { BasePayPalDTO, PayPalPaymentDTO } from "./domain/PayPalPaymentDTO";
import PayPalService from "./domain/PayPalService";
import { payPalPaymentMapperFactory } from "./PayPalPaymentMapper";
import { BaseServiceImpl } from "../../services/BaseServiceImpl";
import { DonateDTO } from "../../domain/models/dto/DonateDTO";
import { PayPalPayment } from "./domain/PayPalPayment";

export default class PayPalServiceImpl
    extends BaseServiceImpl
    implements PayPalService
{
    async createPayment(
        total: number,
        currency: string
    ): Promise<PayPalPaymentDTO> {
        if (!total || !currency) {
            return;
        }

        try {
            return payPalPaymentMapperFactory().fromDTO(
                await this.post<PayPalPaymentDTO>("/api/v1/paypal/payment", {
                    total,
                    currency,
                })
            );
        } catch (err) {
            console.log("PayPalServiceImpl.createPayment => ERROR:");
            console.log(err);
            return null;
        }
    }

    async executeDonatePayment(
        payPalDTO: BasePayPalDTO,
        donate: DonateDTO
    ): Promise<PayPalPayment> {
        if (
            !payPalDTO?.paypalPaymentId ||
            !payPalDTO?.paypalPayerId ||
            !donate
        ) {
            return;
        }

        try {
            return payPalPaymentMapperFactory().fromDTO(
                await this.post("/api/v1/paypal/payment/donate/execute", {
                    ...payPalDTO,
                    ...donate,
                })
            );
        } catch (err) {
            console.log("PayPalServiceImpl.executeDonatePayment => ERROR:");
            console.log(err);
            return null;
        }
    }
}

export function payPalServiceFactory(): PayPalService {
    return new PayPalServiceImpl();
}
