import { FC } from "react";
import BaseModal from "../../shared/modals/BaseModal";
import { useTranslation } from "react-i18next";

interface DonateSuccessModalProps {
    isVisible: boolean;
    setVisibility: (value: boolean) => void;
}

const DonateSuccessModal: FC<DonateSuccessModalProps> = ({ isVisible, setVisibility }) => {
    const { t } = useTranslation();
    return (
        <BaseModal
            isVisible={isVisible}
            setIsVisible={setVisibility}
            title={t("donateForm.supportTitle")}
            confirmTitle={t("messages.ok")}
            closingCrossClassName="cursor-pointer w-5 h-5"
            confirmHandler={() => setVisibility(false)}
            closeHandler={() => setVisibility(false)}
            additionalTitleClassName="font-manrope !text-brown3 !text-[24px] text-center px-4"
            additionalChildWrapClassName="!bg-beige4 p-6"
            buttonElement={<></>}
            disableCancelButton
            additionalConfirmButtonClassName="font-cormorant !text-beige1 flex items-center justify-center bg-button-gradient w-[174px] h-[37px] font-semibold py-[5px] px-5 rounded-full cursor-pointer"
        >
            <p className="font-manrope text-[16px] text-brown3 leading-[23.2px] text-center">{t("messages.sent")}</p>
        </BaseModal>
    );
};

export default DonateSuccessModal;
