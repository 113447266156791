import { FC } from "react";
import { useTranslation } from "react-i18next";
import { accountOwner, paypal, paypalDisable, privatBankCard } from "../../Config";
import DonateForm from "../header/donate-form/DonateForm";

interface ContributionSectionProps {
    blockClassName?: string;
}

const ContributionSection: FC<ContributionSectionProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className={`relative ${props.blockClassName} h-[514px] z-50`}>
            <div className="relative left-0 right-0 m-auto h-full overflow-x-clip">
                <div className="absolute left-[300px] right-0 m-auto pt-[57px] w-fit">
                    <div className="absolute z-[100] right-[850px] top-[-70px] w-[300px] h-[350px] transform rotate-[11.7deg] bg-gradient-to-b from-[#FAECC4] to-[#E9DCB2] p-[20px] shadow-familyPhoto">
                        <img className="max-w-none w-full" src="/img/search-page/contribution1.png" alt="Top" />
                    </div>
                    <div className="absolute z-[110] right-[950px] top-0 w-[450px] h-[530px] transform rotate-[-16.3deg] bg-gradient-to-b from-[#FAECC4] to-[#E9DCB2] p-[20px] shadow-familyPhoto">
                        <img className="max-w-none w-full" src="/img/search-page/contribution2.png" alt="Top" />
                    </div>
                    <div className="absolute z-[100] left-[850px] top-[10px] w-[400px] h-[495px] transform rotate-[16.2deg] bg-gradient-to-b from-[#FAECC4] to-[#E9DCB2] p-[20px] shadow-familyPhoto">
                        <img className="max-w-none w-full" src="/img/search-page/contribution3.png" alt="Top" />
                    </div>
                    <p className="font-cormorant text-[50.4px] leading-[53px] text-brown3">
                        {t("surnameMapPage.contributionSection.text1")}
                    </p>
                    <p className="font-manrope text-[22px] leading-[32px] text-brown3 mt-[17px]">
                        {t("surnameMapPage.contributionSection.text2")}
                    </p>
                    <div className="mt-[31px]">
                        {paypalDisable && (
                            <p className="font-manrope text-[22px] leading-[32px] text-brown3">
                                <span className="font-bold">{t("surnameMapPage.contributionSection.text10")}</span>
                                {paypal}
                            </p>
                        )}
                        <p className="font-manrope text-[22px] leading-[32px] text-brown3 mt-[6px]">
                            <span className="font-bold">{t("surnameMapPage.contributionSection.text11")}</span>
                            {privatBankCard}
                        </p>
                        <p className="font-manrope text-[22px] leading-[32px] text-brown3 mt-[6px]">
                            <span className="font-bold">{t("surnameMapPage.contributionSection.text7")}</span>
                            {accountOwner}
                        </p>
                        <p className="font-manrope text-[22px] leading-[32px] text-brown3 mt-[6px]">
                            <span className="font-bold">{t("surnameMapPage.contributionSection.text8")}</span>
                            {t("surnameMapPage.contributionSection.text9")}
                        </p>
                    </div>
                    {paypalDisable ? (
                        <></>
                    ) : (
                        <p className="flex font-manrope text-[22px] leading-[32px] text-brown3 mt-8">
                            <span className="font-bold">{t("surnameMapPage.contributionSection.text10")}</span>
                            <DonateForm className="" buttonElementClassName="!ml-4" />
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContributionSection;
