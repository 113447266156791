import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

interface CommonErrorProps<T> {
    form?: FormikProps<T>;
    errorKey: string;
    className?: string;
}

function CommonError<T>(props: CommonErrorProps<T>) {
    const error = props.form ? (Object.values(props.form.errors).find((value) => value) as string) : "";
    const { t } = useTranslation();
    return (
        <div
            className={
                error || props.errorKey ? `${props.className} mt-1 ml-3 text-sm font-normal text-red-500` : "hidden"
            }
        >
            {error || t(props.errorKey)}
        </div>
    );
}

export default CommonError;
