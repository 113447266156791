import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { paypalClientId } from "../../../Config";
import { BasePayPalDTO } from "../domain/PayPalPaymentDTO";
import languageServiceFactory from "../../../services/LanguageServiceImpl";
import { payPalServiceFactory } from "../PayPalServiceImpl";
import { PayPalPayment } from "../domain/PayPalPayment";
import { Component } from "react";
import PayPalService from "../domain/PayPalService";

const CURRENCY = "EUR";
export interface PayPalCheckoutButtonButtonProps<T> {
    value: number;
    data: T;

    isValidData: boolean;

    setCommonPaymentError: () => void;
    paymentHandler: (paymentResult: PayPalPayment) => void;

    executeServiceMethod: (paypalDTO: BasePayPalDTO, data: T) => Promise<PayPalPayment>;
}

const paypalService = payPalServiceFactory();
const langService = languageServiceFactory();

export class PayPalCheckoutButton<T> extends Component<PayPalCheckoutButtonButtonProps<T>, any> {
    service: PayPalService;

    constructor(props: PayPalCheckoutButtonButtonProps<T>) {
        super(props);
        this.service = payPalServiceFactory();
    }

    render = () => {
        return (
            <>
                <PayPalScriptProvider
                    options={{
                        "client-id": paypalClientId,
                        "disable-funding": "paylater,credit",
                        commit: true,
                        currency: CURRENCY,
                        locale: langService.currentPaypalLang,
                    }}
                >
                    <PayPalButtons
                        style={{
                            color: "blue",
                            shape: "pill",
                        }}
                        disabled={!this.props.isValidData}
                        createOrder={async (_data, _actions) => {
                            const response = await paypalService.createPayment(this.props.value, CURRENCY);
                            if (!response?.links.length) {
                                this.props.setCommonPaymentError();
                                return;
                            }

                            var token;
                            for (let link of response.links) {
                                if (link.rel === "approval_url") {
                                    token = link.href.match(/EC-\w+/)[0];
                                }
                            }
                            return token;
                        }}
                        onApprove={async (data, _actions) => {
                            const result = await this.props.executeServiceMethod(
                                {
                                    paypalPaymentId: data.paymentID,
                                    paypalPayerId: data.payerID,
                                    lang: langService.currentLang,
                                },
                                this.props.data
                            );
                            this.props.paymentHandler(result);
                        }}
                    ></PayPalButtons>
                </PayPalScriptProvider>
            </>
        );
    };
}
