import { Langs } from "../domain/enums/common/LangsEnum";
import { CustomerSupportEmailDTO } from "../domain/models/dto/CustomerSupportDTO";
import { CustomerSupportService } from "../domain/services/CustomerSupportService";
import { BaseServiceImpl } from "./BaseServiceImpl";

class CustomerSupportServiceImpl
    extends BaseServiceImpl
    implements CustomerSupportService
{
    async sendEmail(
        dto: CustomerSupportEmailDTO,
        lang: Langs
    ): Promise<boolean> {
        try {
            return !!(await this.post("/api/v1/customer-support/send-email", {
                ...dto,
                lang,
            }));
        } catch (err) {
            console.log("Error in CustomerSupportServiceImpl.sendEmail");
            console.log(err);
        }
    }
}

export default function customerSupportServiceFactory(): CustomerSupportService {
    return new CustomerSupportServiceImpl();
}
