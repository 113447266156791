import { FC } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ContactsIntro from "./ContactsIntro";
import ContactsSendEmail from "./ContactsSendEmail";

const ContactsPage: FC = () => {
    return (
        <>
            <Header />
            <ContactsIntro />
            <ContactsSendEmail />
            {/* <ContributionSection blockClassName="bg-beige1" /> */}
            <Footer />
        </>
    );
};

export default ContactsPage;
