import { FC } from "react";
import { Archive } from "../../domain/models/Archive";
import languageServiceFactory from "../../services/LanguageServiceImpl";

interface ArchivesTableRowProps {
    archive: Archive;
    index: number;
}

const languageService = languageServiceFactory();

const ArchivesTableRow: FC<ArchivesTableRowProps> = (props) => {
    return (
        <div className={`flex justify-between border-r border-b border-brown3 py-[62px] px-[44px]`}>
            <div className="font-manrope font-bold text-[26.4px] text-brown3 leading-[38.2px] w-[48%]">
                {languageService.isUkrainian ? props.archive.nameUk : props.archive.nameEn}
            </div>
            <div className="font-manrope text-[22px] leading-[28.6px] text-brown3 w-[48%] break-words">
                {languageService.isUkrainian ? props.archive.addressUk : props.archive.addressEn} <br />
                {props.archive.phoneStr}
                <br />
                {props.archive.emailStr}
                <br />
                <span className="font-bold">{props.archive.site}</span>
            </div>
        </div>
    );
};

export default ArchivesTableRow;
