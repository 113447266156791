import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";
import { schemaFactory } from "./SchemaFactory";
import { CustomerSupportEmailDTO } from "../domain/models/dto/CustomerSupportDTO";

export const customerSupportEmailFields: Fields<CustomerSupportEmailDTO> = {
    clientName: {
        min: 1,
        max: 255,
        placeholderKey: "",
        type: "text",
        initial: "",
    },
    clientEmail: {
        min: 1,
        max: 128,
        placeholderKey: "",
        type: "email",
        initial: "",
    },
    message: {
        min: 1,
        max: 1000,
        placeholderKey: "",
        type: "text",
        initial: "",
    },
};

export const customerSupportEmailSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, customerSupportEmailFields);
};
