import { FC } from "react";
import { useTranslation } from "react-i18next";

interface CustomCheckboxProps {
    labelKey: string;
    checked: boolean;
    disabled?: boolean;
    onClick: () => void;
}

const CustomCheckbox: FC<CustomCheckboxProps> = (props) => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (!props.disabled) {
            props.onClick();
        }
    };

    return (
        <div className="flex items-center space-x-2">
            <div
                onClick={handleClick}
                className={`w-6 h-6 border-2 border-brown1 rounded-sm cursor-pointer transition duration-200 ${
                    props.checked ? "bg-brown1" : "bg-white"
                } ${props.disabled ? "opacity-50 cursor-default" : ""}`}
            ></div>
            {props.labelKey && (
                <span className={`text-gray-700 ${props.disabled ? "opacity-50" : ""}`}>{t(props.labelKey)}</span>
            )}{" "}
        </div>
    );
};

export default CustomCheckbox;
