import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";
import { schemaFactory } from "./SchemaFactory";
import { DonateForm } from "../domain/models/dto/DonateDTO";

export const donateFormFields: Fields<DonateForm> = {
    value: {
        min: 1,
        max: 999999,
        placeholderKey: "donateForm.value",
        type: "number",
        initial: null,
    },
    clientEmail: {
        min: 1,
        max: 200,
        placeholderKey: "donateForm.clientEmail",
        type: "email",
        initial: "",
        optional: true,
    },
};

export const donateFormSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, donateFormFields);
};
