import { SimpleMapper } from "../../mappers/SimpleMapper";
import { PayPalPaymentDTO } from "./domain/PayPalPaymentDTO";
import { PayPalPayment } from "./domain/PayPalPayment";
import { PayPalPaymentModel } from "./PayPalPaymentModel";
import { Mapper } from "../../domain/mappers/Mapper";

export class PayPalPaymentMapper extends SimpleMapper<
    PayPalPayment,
    PayPalPaymentDTO
> {
    protected fromDTOFields: string[] = ["state", "transactionId", "links"];
    protected entityConstructor = PayPalPaymentModel;
}

export function payPalPaymentMapperFactory(): Mapper<
    PayPalPayment,
    PayPalPaymentDTO
> {
    return new PayPalPaymentMapper();
}
