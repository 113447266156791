import { ArchivesTypes } from "./domain/enums/ArchivesTypesEnum";
import { Langs } from "./domain/enums/common/LangsEnum";
import { PaginationEnum } from "./domain/enums/common/PaginationEnum";
import { StatusCodes } from "http-status-codes";

export const translations = {
    ua: {
        translation: {
            languages: {
                [Langs.UA]: "Українська",
                [Langs.EN]: "English",
            },
            pagination: {
                show: "Показувати",
                [PaginationEnum.SHOW_10]: "10",
                [PaginationEnum.SHOW_20]: "20",
                [PaginationEnum.SHOW_50]: "50",
                [PaginationEnum.SHOW_100]: "100",
            },
            errors: {
                common: "Несподівана помилка",
                validation: {
                    min: "Мінімальне значення ",
                    max: "Максимальне значення ",
                    required: "Обов'язкове поле",
                    invalidOption: "Некоректне значення",
                    invalidNumber: "Некоректне число",
                    invalidEmail: "Некоректна адреса електронної пошти",
                    requireAtLeastOne:
                        "Обов'язкова наявність хоча б одного з полів",
                    invalidPhone: "Некоректний номер телефону",
                    password: {
                        minSymbols:
                            "Мінімальна кількість спеціальних символів - 1",
                        minLowercase:
                            "Мінімальна кількість символів в нижньому регістрі - 2",
                        minUppercase:
                            "Мінімальна кількість символів в верхньому регістрі - 1",
                        minNumbers: "Мінімальна кількість чисел - 1",
                        confirm: "Паролі не співпадають",
                    },
                    syntaxError: "Присутнє некоректне значення",
                },
                response: {
                    [StatusCodes.UNAUTHORIZED]:
                        "Несанкціонований доступ або невірний пароль",
                    [StatusCodes.BAD_REQUEST]: "Присутнє некоректне значення",
                },
            },
            messages: {
                received: "Дані отримано",
                created: "Запис успішно створено",
                updated: "Запис успішно оновлено",
                deleted: "Запис успішно видалено",
                sent: "Платіж пройшов успішно, дякуємо!",
                copied: "Дані скопійовано",
                ok: "Ok",
            },
            navigation: {
                aboutProject: "ПРО ПРОЄКТ",
                surnameMap: "КАРТА ПРІЗВИЩ",
                recommendations: "РЕКОМЕНДАЦІЇ",
                archives: "АРХІВИ",
                dnaGenealogy: "ДНК-ГЕНЕАЛОГІЯ",
                contacts: "КОНТАКТИ",
            },
            donateForm: {
                actionCallText:
                    "Ваші донати – це розвиток проєкту та нові функції у ",
                actionCallTitle: "Підтримати проєкт",
                supportTitle: "Підтримка проєкта",
                projectName: "History of Generations",
                value: "Введіть сумму:",
                clientEmail: "Введіть Ваш email (не обов'язково):",
            },
            aboutPage: {
                heroSection: {
                    text1: "Якщо ви читаєте ці рядки, то життя продовжується!",
                    text2: "А ви, скоріше за все, намагаєтесь",
                    text3: "когось знайти.",
                    text4: "Або щось ",
                    text5: "про когось дізнатись.",
                    text6: "Ймовірно, ця людина народилася",
                    text7: "на території сучасної України.",
                    searchButton: "РОЗПОЧАТИ ПОШУК",
                },
                genealogistInfoSection: {
                    text1: "ВИ – ЛЮДИНА, ЯКА ЦІКАВИТЬСЯ ІСТОРІЄЮ РОДИНИ, РОДУ. ВИ –",
                    text2: "ГЕНЕАЛОГ",
                    text3: "САМЕ ДЛЯ ВАС СТВОРЕНО ПРОЄКТ",
                    text4: "«HISTORY OF GENERATIONS»",
                    text5: "«History of Generations» – це пошукова система, основна мета якої – допомогти вам у визначенні ареалів виникнення тих чи інших прізвищ.",
                    text6: "Ваших прізвищ.",
                    text7: "Це - стиснута історія життя ваших предків.",
                    text8: "Початківець, «просунутий», або професіонал.",
                    text9: "Шукаєте собі, або допомагаєте іншим.",
                    text10: "Ви віддаєте перевагу",
                    text11: "самостійному пошуку чи шукаєте додаткові джерела інформації",
                },
                generationalHistorySection: {
                    text1: "ДЛЯ ЧОГО ЦЕ ТА ЯК ПРАЦЮЄ?",
                    text2: "ВВАЖАЄТЬСЯ, ЩО ПЕРША МАСОВА ЕМІГРАЦІЯ УКРАЇНЦІВ РОЗПОЧАЛАСЬ У",
                    text3: "ДАЛЕКОМУ 1865-МУ РОЦІ.",
                    text4: "З цього часу пройшло вже 159 років.",
                    text5: "Кожні двадцять років з'являється нове покоління. Отже, нині за кордонами України може мешкати вже восьме покоління тих, хто подався у пошуках кращого життя на Захід.",
                    text6: "БАГАТО ЦЕ ЧИ МАЛО?",
                    text7: "Кажуть, що у «середньостатистичній» родині знають та пам'ятають лише про чотири покоління: дітей, батьків, дідів та прадідів. Тобто, щонайменше,",
                    text8: "половина історії",
                    text9: "родини українських емігрантів їхнім (теперішнім)",
                    text10: "нащадкам невідома.",
                },
                familyStoriesSection: {
                    text1: "І ДОБРЕ, КОЛИ ЗБЕРЕГЛИСЬ ЯКІСЬ ДОКУМЕНТИ.",
                    text2: "А ЯКЩО – НІ?",
                    text3: "ЯКЩО ЄДИНЕ НАДБАННЯ – РОЗПОВІДІ ТА РОДИННІ ЛЕГЕНДИ?",
                    text4: "Легенди про життя когось та десь.",
                    text5: "Дуже часто в таких розповідях власні назви (імена, назви населених пунктів, прізвища) неточні, перекручені чи навіть спотворені.",
                    text6: "Чому?",
                    text7: "Бо їх записували на слух люди, коли ніколи раніше нічого подібного не чули. Вони передавались (також на слух) нащадкам, які не знали їх правильного написання. Вони існували в іншому мовному середовищі.",
                    text8: "Або у вас є правильне написання прізвища, але ви не знаєте, де саме народився та жив пращур...",
                    text9: "Або ви не знаєте, як правильно пишеться назва населеного пункту...",
                    text10: "Або в Україні декілька населених пунктів мають однакову назву...",
                    text11: "В усіх цих випадках намагання знайти архівні документи виявляються дуже важкими.",
                },
                resourceHelperSection: {
                    text1: "ТОЖ ЦЕЙ РЕСУРС МАЄ ДОПОМОГТИ ВАМ:",
                    text2: "у пошуку правильного написання прізвища;",
                    text3: "у локалізації найбільш вірогідних місць народження та проживання людей з таким прізвищем;",
                    text4: "у пошуку правильного написання назви населеного пункту;",
                    text5: "у локалізації цього населеного пункту на мапі України (у розробці);",
                    text6: "у пошуку населеного пункту за географічними координатами (у розробці).",
                    text7: "Наша Родзинка",
                    text8: "А ще у цієї пошукової системи є своя «родзинка».",
                    text9: "Це – можливість пошуку за двома прізвищами одночасно. Чому це важливо та які це дає переваги – на сторінці «Карта прізвищ».",
                },
            },
            searchRecommendationsPage: {
                searchIntro: {
                    text1: "РЕКОМЕНДАЦІЇ",
                    text2: "ЩОДО ПОШУКУ",
                    text3: "Для ідеального пошуку треба мати повні ім’я, по батькові (ім’я батька), прізвище (для жінки - дівоче) та назву населеного пункту.",
                    text4: "Але це – ідеальний варіант.",
                    text5: "рекомендації",
                },
                familyInterviewAdvice: {
                    text1: "На практиці часто буває, що вихідні дані неповні.",
                    text2: "Або імена та назви неточні.",
                    text3: "ТОМУ ПЕРША РЕКОМЕНДАЦІЯ –",
                    text4: "ОПИТАТИ РІДНЮ.",
                    text5: "Навіть, якщо вона живе далеко чи якщо у вас з нею не дуже гарні відносини. До речі, може саме інтерес до спільних предків стане тим, що вас об’єднає (або просто покращить стосунки).",
                    text6: "Крім того, як показує практика, початково правильний напрямок пошуку може значно заощадити час, зусилля та гроші.",
                    text7: "Тобто, перше, що треба зробити – опитати рідню.",
                    text8: "Бажано – найстаршу. Бажано – жінок.",
                    text9: "Чому жінок? Бо, зазвичай, саме дівчатка проводять більше часу  з матусею чи бабусею. А більше часу означає більше розповідей. Розповідей, які вони почують від мами чи бабусі. Розповідей про минулі часи чи про життя їх батьків, дідів, прадідів. Так, звичайно, хлопці теж можуть щось запам’ятати. Але хлопці більше зайняті «зовнішньою діяльністю» - іграми на свіжому повітрі.",
                },
                interviewInstructions: {
                    text1: "Основне правило: чим більше рідні вам вдасться опитати – тим краще.",
                    text2: "Є Й РЕКОМЕНДАЦІЇ ЩОДО САМОГО ОПИТУВАННЯ:",
                    text3: "Опитуйте по заздалегідь",
                    text4: "розробленій анкеті.",
                    text5: "Обов’язково",
                    text6: "фотографуйте",
                    text7: "та",
                    text8: "скануйте",
                    text9: "документи предків, листи, листівки та фотографії. Причому – з двох сторін. Обов’язково",
                    text10: "«підписуйте»",
                    text11: "фотографії разом з тим, кого опитуєте. Прізвища близької та/чи далекої рідні можуть статися в нагоді, коли ви, наприклад, зустрінете когось зі значущим збігом ДНК.",
                    text12: "Записуйте опитування на",
                    text13: "аудіо",
                    text14: "чи",
                    text15: "відео.",
                    text16: "Згодом ви зможете переслухати чи передивитись і зрозуміти, почути те, що не зрозуміли чи не почули під час опитування.",
                },
                archivesConsultationGuide: {
                    text1: "На другому етапі (коли ви вже знаєте, кого, коли та де шукати), можете",
                    text2: "ЗВЕРТАТИСЬ ДО АРХІВІВ",
                    text3: "Кожен з них має вебову сторінку (її англомовну версію) та адресу електронної пошти. Також на сайтах є й зразки форм для запитів. Але, в більшості випадків, українською.",
                    text4: "Тому",
                    text5: "translate.google",
                    text6: "буде не зайвим.",
                    text7: "За неповними даними архіви, нажаль, пошук не проводять.",
                    text8: "Основна причина – неможливість такого пошуку чи відсутність необхідної кількості фахівців.",
                    text9: "Але бувають випадки, коли навіть при всіх вихідних даних ви отримаєте негативну відповідь. Чому так? Причини різні. Зазвичай – неповна або погана збереженість фондів. Або помилка фахівця (так званий людський фактор). Або зберігання в іншому архіві.",
                    text10: "СПИСОК АРХІВІВ",
                },
                researchPreparationTips: {
                    text1: "Тому бажано спочатку провести попередній самостійний аналіз фондів архіву. Проконсультуватись на тематичних форумах. Попрацювати з путівником, описами.",
                    text2: "Останнім часом все більшої популярності набуває самостійний пошук за відсканованими документами. Багато з них вже є на",
                    text3: "familysearch.org",
                    text4: "або на сайтах самих архівів. Але цей процес розпочався відносно нещодавно, йде з певними перервами, перешкодами та займе ще деякий час. Тому не все, що є в архіві, зараз відскановане та є у вільному доступі.",
                    text5: "Окремі рекомендації щодо пошуку стосуються тих, хто був",
                    text6: "всиновлений",
                    text7: "або перебував у",
                    text8: "дитячому будинку.",
                    text9: " В цих випадках бажано рухатись за «документальним ланцюжком»: документи про натуралізацію, всиновлення, народження. В цьому випадку можуть бути перешкоди у вигляді так званої таємниці усиновлення, але це вже робота для адвокатів, юристів чи, навіть, приватних детективів.",
                },
            },
            DNAGenealogyPage: {
                DNAIntro: {
                    text1: "ДНК-ГЕНЕАЛОГІЯ",
                    text2: "Зазвичай, є альтернативним, допоміжним напрямком генеалогічного пошуку. Але, іноді, ДНК-тестування стає єдиним інструментом.",
                    text3: "Наприклад, якщо йдеться про всиновлення",
                    text4: "або перебування у дитячому будинку.",
                    text5: "днк-генеалогія",
                },
                researchBenefits: {
                    text1: "ЧИМ МОЖЕ ДОПОМОГТИ РЕСУРС",
                    text2: "«HISTORY OF GENERATIONS»",
                    text3: "В ЦЬОМУ ВИПАДКУ?",
                    text4: "Якщо вам відомі прізвища біологічних батьків, ви зможете визначити ареали їх виникнення. На другому етапі – знайти відповідних носіїв прізвищ у соціальних мережах та домовитись з ними про ДНК-тестування.",
                    text5: "Або, якщо, наприклад, йдеться про медико-генетичне дослідження, спланувати вибірку та географію.",
                    text6: "ЄДИНЕ, ПРО ЩО ТРЕБА ПАМ’ЯТАТИ:",
                    text7: "аутосомний ДНК-тест буде ефективний лише на «глибину» у 8 поколінь (трохи більше – у ендогамних етносів). Більшу ж глибину дають Big Y (Y-DNA) та mtDNA тести. Але Big Y – лише чоловіча лінія (син, батько, дід), mtDNA – лише жіноча лінія (дочка, мати, бабуся).",
                },
                researchDesignOptions: {
                    text1: "Та у випадку об’ємних, складних досліджень ресурс",
                    text2: "«History of Generations»",
                    text3: "може стати цінним інструментом.",
                    text4: "В цьому випадку дизайн дослідження може бути таким:",
                    text5: "визначення основних ареалів виникнення прізвища;",
                    text6: "умовно масове ДНК-тестування носіїв прізвища із різних ареалів за допомогою недорогих тест-систем;",
                    text7: "визначення носіїв з найбільшим збігом;",
                    text8: "тестування відібраних носіїв у більш інформативний спосіб (Big Y, mtDNA).",
                    text9: "На цю тему існує багато досліджень та публікацій.",
                    text10: "В тому числі і моє, кабінетне дослідження",
                    text11: "«Analysis of DNA matching using methods of experimental biology»",
                },
            },
            surnameMapPage: {
                surnameMapSection: {
                    legend: {
                        indicator1: "Від 1 до 10",
                        indicator2: "Від 11 до 20",
                        indicator3: "Від 21 до 50",
                        indicator4: "51 і більше",
                    },
                    xlsxDownloadText: "Ви можете завантажити результат",
                    xlsxTitle: "Завантажити xlsx файл",
                    xlsxFields: {
                        regionUk: "Область (укр)",
                        regionEn: "Область (англ)",
                        districtUk: "Район (укр)",
                        districtEn: "Район (англ)",
                        localityUk: "Населений пункт (укр)",
                        localityEn: "Населений пункт (англ)",
                        fathersCount: "Поширення прізвища батька",
                        mothersCount: "Поширення прізвища матері",
                    },
                    spreadFathersLastName: "Поширення прізвища батька",
                    spreadMothersLastName: "Поширення прізвища матері",
                    lastNameFathers: "Прізвище батька",
                    lastNameMothers: "Прізвище матері",
                    search: "ПОШУК",
                    surnameMapTable: {
                        number: "№",
                        district: "Район",
                        locality: "Населений пункт",
                        A: "А",
                        B: "Б",
                    },
                    text1: "НАЗВА ВИДІЛЕНОГО РАЙОНУ",
                    text2: "А) Кількість людей з прізвищем батька",
                    text3: "Б) Кількість людей з прізвищем матері",
                    text4: "Карта прізвищ",
                    text5: "розроблена таким чином, щоб ви могли шукати як за одним прізвищем, так і за двома одночасно.",
                    text6: "ЧОМУ ЦЕ ВАЖЛИВО, ЯК ЦЕ ПРАЦЮЄ ТА ЯКІ ЦЕ ДАЄ ПЕРЕВАГИ?",
                    text7: "Наведу приклад. Мій дідусь Стадничук був одружений на бабусі Козоріз. Якщо шукати окремо, то Стадничуки народжувались у 19-ти населених пунктах. Козоріз – у 130-ти. Спільний пошук (за двома прізвищами одночасно) дає лише три варіанти.",
                    text8: "Тобто пошук по двох прізвищах одночасно",
                    text9: "скоротив кількість варіантів у 6-43 рази!",
                    text10: "Коли йдеться про пошук людини чи родини, йдеться про людей, які  народились в певний час та в певному місці. Але що робити, коли відомий вам населений пункт не знаходиться? Варіантів може бути декілька:",
                    text11: "відома вам назва не є правильною;",
                    text12: "населений пункт перейменовано;",
                    text13: "населений пункт припинив існування (наприклад, став частиною сусіднього міста).",
                    text14: "В будь якому випадку, після того, як ви отримаєте результати пошуку за прізвищем, ви отримаєте й перелік населених пунктів, у яких народились носії цього прізвища. Може статись, що ви відразу впізнаєте свій (населений пункт). Чи вам доведеться перевірити їх усі і, наприклад, в статті з Wikipedia ви знайдете відому вам, але стару (яку вже не використовують) назву цього населеного пункту.",
                    text15: "Або в розповідях, легендах йшлося про те, що ваше родинне гніздо «було біля …» чи «пішки ходили до…». В цьому випадку треба буде подивитись, що знаходиться поряд с тими населеними пунктами, які ви отримали в результаті пошуку за прізвищем.",
                    text16: "Прізвище чоловіків по батьківській лінії",
                    text17: "Призвище чоловіків по материнській лінії",
                },
                contributionSection: {
                    text1: "СТАНЬТЕ ЧАСТИНОЮ ПРОЄКТУ!",
                    text2: "В першу чергу – підтримавши проєкт History of Generations фінансово.",
                    text3: "Назва банку: ",
                    text4: "Адреса: ",
                    text5: "IBAN: ",
                    text6: "SWIFT: ",
                    text7: "Власник рахунку: ",
                    text8: "Призначення платежу: ",
                    text9: "Підтримка проєкту History of Generations",
                    text10: "PayPal: ",
                    text11: "Карта ПриватБанку: ",
                },
            },
            archivesPage: {
                type: {
                    null: "Оберіть тип архіву",
                    [ArchivesTypes.REGIONAL]: "Обласний",
                    [ArchivesTypes.CENTRAL]: "Центральний",
                    [ArchivesTypes.SECTORAL]: "Галузевий",
                },
                archivesIntro: {
                    text1: "Архіви",
                    text2: "Основна генеалогічна інформація зберігається у",
                    text3: "27-и обласних, 7-и центральних",
                    text4: "та",
                    text5: "15-ти галузевих",
                    text6: "державних архівах.",
                    text7: "Іноді щось можна зустріти в музеях, приватних колекціях чи, навіть, на аукціонах. Але такі випадки носять несистемний, поодинокий характер.",
                    text8: "Розпочинати ж пошуки варто саме з державних обласних архівів.",
                    text9: "архіви",
                },
                central: "Центральні Державні Архіви",
                regional: "Обласні Державні Архіви",
                sectoral: "Галузеві Державні Архіви",
            },
            contactsPage: {
                contactsIntro: {
                    text1: "МЕНЕ ЗВУТЬ",
                    text2: "ВОЛОДИМИР СТАДНИЧУК",
                    text3: "У цьому світі я вже 50 років (відомості на 2024 рік 😊)",
                    text4: "Далекого 1999 року я закінчив навчання в одному з українських університетів за спеціальністю «біолог, фізіолог». Дев'ять років навчання та шість років лабораторної практики. Займався одним із напрямків експериментальної біології.",
                    text5: "Вже близько",
                    text6: "20-ти років",
                    text7: "я захоплююсь",
                    text8: "генеалогією",
                    text9: "Пошуки розпочинав для себе. Зараз, здебільшого, допомагаю іншим. Вірю, що і в такий спосіб також",
                    text10: "можна зробити світ кращим",
                    text11: "контакти",
                },
                contactsSendEmail: {
                    text1: "ВИ МОЖЕТЕ ЗВ’ЯЗАТИСЬ ЗІ МНОЮ У НАСТУПНИЙ СПОСІБ:",
                    text2: "Ваше ім’я:",
                    text3: "Ваш Email:",
                    text4: "Повідомлення:",
                    text5: "ВІДПРАВИТИ",
                    successMessage: "Повідомлення було успішно надіслано",
                },
            },
            admin: {
                login: {
                    title: "Вхід",
                    action: "Увійти",
                    email: "Адреса електронної пошти",
                    password: "Пароль",
                },
                sidebar: {
                    menu: "Меню",
                    expand: "Розширити меню",
                    residentsPage: "Список осіб",
                    archivesPage: "Архіви",
                    districtsPage: "Райони",
                    settingsPage: "Налаштування",
                    logOut: "Вийти",
                },
                common: {
                    create: "Створити новий запис",
                    update: "Оновити",
                    delete: "Видалити",
                    deleteText: "Точно видалити цей запис?",
                    filters: "Фільтри",
                    filtersReset: "Скасувати фільтри",
                    apply: "Застосувати",
                    cancel: "Скасувати",
                    ok: "OK",
                },
                residentsPage: {
                    tableColumns: {
                        firstName: "Ім'я",
                        lastName: "Прізвище",
                        country: "Країна",
                        region: "Область",
                        districtName: "Район",
                        locality: "Населений пункт",

                        firstNameUk: "Ім'я (Українська)",
                        lastNameUk: "Прізвище (Українська)",
                        countryUk: "Країна (Українська)",
                        regionUk: "Область (Українська)",
                        districtNameUk: "Район (Українська)",
                        localityUk: "Населений пункт (Українська)",

                        firstNameEn: "Ім'я (Англійська)",
                        lastNameEn: "Прізвище (Англійська)",
                        countryEn: "Країна (Англійська)",
                        regionEn: "Область (Англійська)",
                        districtNameEn: "Район (Англійська)",
                        localityEn: "Населений пункт (Англійська)",
                    },
                },
                archivesPage: {
                    tableColumns: {
                        name: "Назва",
                        address: "Адреса",
                        phoneStr: "Телефон",
                        emailStr: "Email",
                        site: "Сайт",
                        type: "Тип",

                        nameUk: "Назва (Українська)",
                        addressUk: "Адреса (Українська)",

                        nameEn: "Назва (Англійська)",
                        addressEn: "Адреса (Англійська)",
                    },
                    creationModal: {
                        addPhone: "Додати телефон",
                        addEmail: "Додати Email",
                    },
                },
                districtsPage: {
                    tableColumns: {
                        district: "Назва району",
                        districtUk: "Назва району (Українська)",
                        districtEn: "Назва району (Англійська)",
                        geo: "Координати у форматі GeoJSON",
                        geoStr: "Координати",
                    },
                },
                settingsPage: {
                    changePassword: {
                        changePasswordTitle: "Зміна пароля адміністратора",
                        currentPassword: "Поточний пароль",
                        newPassword: "Новий пароль",
                        confirmNewPassword: "Підтвердити новий пароль",
                        buttonTitle: "Змінити пароль",
                        successTitle1: "Пароль успішно змінено.",
                        successTitle2: "Треба увійти до акаунту.",
                    },
                },
            },
        },
    },

    en: {
        translation: {
            languages: {
                [Langs.UA]: "Українська",
                [Langs.EN]: "English",
            },
            pagination: {
                show: "Show",
                [PaginationEnum.SHOW_10]: "10",
                [PaginationEnum.SHOW_20]: "20",
                [PaginationEnum.SHOW_50]: "50",
                [PaginationEnum.SHOW_100]: "100",
            },
            errors: {
                common: "Unexpected error",
                validation: {
                    min: "Minimal value ",
                    max: "Maximum value ",
                    required: "Required field",
                    invalidOption: "Invalid option",
                    invalidNumber: "Invalid number",
                    invalidEmail: "Invalid email address",
                    requireAtLeastOne: "Require at least one of fields",
                    invalidPhone: "Invalid phone number",
                    password: {
                        minSymbols: "Minimum number of special characters - 1",
                        minLowercase: "Minimum lowercase character - 2",
                        minUppercase: "Minimum uppercase character - 1",
                        minNumbers: "Minimum number of numbers - 1",
                        confirm: "Passwords do not match",
                    },
                    syntaxError: "Invalid value is present",
                },
                response: {
                    [StatusCodes.UNAUTHORIZED]:
                        "Unauthorized access or incorrect password",
                    [StatusCodes.BAD_REQUEST]: "Invalid value is present",
                },
            },
            messages: {
                received: "Data received",
                created: "Record created successfully",
                updated: "Record updated successfully",
                deleted: "Record deleted successfully",
                sent: "The payment was successful, thank you!",
                copied: "Data copied",
                ok: "Ok",
            },
            navigation: {
                aboutProject: "ABOUT THE PROJECT",
                surnameMap: "SURNAME MAP",
                recommendations: "RECOMMENDATIONS",
                archives: "ARCHIVES",
                dnaGenealogy: "DNA GENEALOGY",
                contacts: "CONTACTS",
            },
            donateForm: {
                actionCallText:
                    "Your donations mean project development and new features in ",
                actionCallTitle: "Support the project",
                supportTitle: "Support of the project",
                projectName: "History of Generations",
                value: "Enter the amount:",
                clientEmail: "Enter your email (optional):",
            },
            aboutPage: {
                heroSection: {
                    text1: "If you are reading these lines, then life goes on!",
                    text2: "And you are probably trying",
                    text3: "to find someone.",
                    text4: "Or ",
                    text5: "find out something about someone.",
                    text6: "Feasibly, this person was born",
                    text7: "in the territory of modern Ukraine.",
                    searchButton: "START SEARCH",
                },
                genealogistInfoSection: {
                    text1: "YOU ARE A PERSON WHO IS INTERESTED IN FAMILY AND ANCESTRY HISTORY. YOU ARE",
                    text2: "GENEALOGIST",
                    text3: "«HISTORY OF GENERATIONS»",
                    text4: "HAS BEEN CREATED JUST FOR YOU",
                    text5: "«History of Generations» is a search system whose main goal is to help you identify the areas of origin of various surnames.",
                    text6: "Your surnames.",
                    text7: "This is a condensed history of your ancestors' lives.",
                    text8: "Beginner, 'advanced', or professional.",
                    text9: "Searching for yourself or helping others.",
                    text10: "You prefer",
                    text11: "independent searching or you are looking for additional sources of information",
                    text12: "THE PROJECT",
                },
                generationalHistorySection: {
                    text1: "WHAT IS IT FOR AND HOW IT WORKS?",
                    text2: "IT IS CONSIDERED THAT THE FIRST MASS EMIGRATION OF UKRAINIANS",
                    text3: "BEGAN IN THE DISTANT YEAR OF 1865.",
                    text4: "Since then, 159 years have already passed.",
                    text5: "Every twenty years, a new generation appears. Therefore, today, there may be as many as eight generations living outside Ukraine for those who set out in search of a better life in the West.",
                    text6: "IS IT A LOT OR A LITTLE?",
                    text7: "It is said that in an 'average' family, people know and remember only about four generations: children, parents, grandparents and great-grandparents. That means that at least",
                    text8: "half of the history",
                    text9: "of the family of Ukrainian emigrants is unknown to their (present)",
                    text10: "descendants.",
                },
                familyStoriesSection: {
                    text1: "AND IT'S GOOD IF SOME DOCUMENTS HAVE BEEN PRESERVED.",
                    text2: "BUT WHAT IF NOT?",
                    text3: "WHAT IF THE ONLY LEGACY IS STORIES AND FAMILY LEGENDS?",
                    text4: "Legends about someone's life somewhere.",
                    text5: "Very often in such stories, proper names (names, names of settlements, surnames) are inaccurate, distorted, or even altered.",
                    text6: "Why?",
                    text7: "Because they were recorded phonetically by people who had never heard anything like it before. They were passed down (also phonetically) to descendants who did not know the correct spelling. They existed in a different linguistic environment.",
                    text8: "Or you have the correct spelling of the surname, but you do not know where exactly your ancestor was born and lived...",
                    text9: "Or you do not know how to correctly spell the name of the settlement...",
                    text10: "Or in Ukraine, several settlements have the same name...",
                    text11: "In all these cases, attempts to find archival documents turn out to be very difficult.",
                },
                resourceHelperSection: {
                    text1: "THUS, THIS RESOURCE IS MEANT TO HELP YOU:",
                    text2: "find the correct spelling of the surname;",
                    text3: "localize the most likely places of birth and residence of people with that surname;",
                    text4: "find the correct spelling of the name of the settlement;",
                    text5: "localize this settlement on the map of Ukraine (under development);",
                    text6: "find the settlement by geographical coordinates (under development).",
                    text7: "Our Highlight",
                    text8: "And this search system also has its own 'highlight'.",
                    text9: "This is the ability to search for two surnames simultaneously. Why is this important and what advantages does it provide – on the 'Surname Map' page.",
                },
            },
            searchRecommendationsPage: {
                searchIntro: {
                    text1: "SEARCH",
                    text2: "RECOMMENDATIONS",
                    text3: "For an ideal search, you need to have the full name, patronymic (father's name), surname (for a woman - maiden name), and the name of the settlement.",
                    text4: "But this is the ideal option.",
                    text5: "recommendations",
                },
                familyInterviewAdvice: {
                    text1: "In practice, it often happens that the initial data is incomplete.",
                    text2: "Or names and titles are inaccurate.",
                    text3: "THEREFORE, THE FIRST RECOMMENDATION IS",
                    text4: "TO ASK RELATIVES.",
                    text5: "Even if they live far away or if you don't have a very good relationship with them. By the way, the interest in common ancestors may be what brings you together (or simply improves your relationship).",
                    text6: "In addition, as practice shows, an initially correct direction for the search can significantly save time, effort, and money.",
                    text7: "That is, the first thing to do is to ask your relatives.",
                    text8: "Preferably the oldest. Preferably women.",
                    text9: "Why women? Because girls usually spend more time with their mothers or grandmothers. And more time means more stories. Stories that they hear from their mothers or grandmothers. Stories about the past or about the lives of their parents, grandparents, and great-grandparents. Yes, of course, boys can also remember something. But boys are more engaged in 'outdoor activities' – playing outside.",
                },
                interviewInstructions: {
                    text1: "The main rule is: the more relatives you manage to ask, the better.",
                    text2: "THERE ARE ALSO RECOMMENDATIONS REGARDING THE SURVEY ITSELF:",
                    text3: "Conduct the survey using",
                    text4: "a pre-prepared questionnaire.",
                    text5: "Make sure",
                    text6: "to photograph",
                    text7: "and",
                    text8: "scan",
                    text9: "your ancestors' documents, letters, postcards, and photographs. Also, do this from both sides. Be sure",
                    text10: "to «label»",
                    text11: "the photographs along with the person you are interviewing. The surnames of close and/or distant relatives may come in handy when, for example, you meet someone with a significant DNA match.",
                    text12: "Record the interview on",
                    text13: "audio",
                    text14: "or",
                    text15: "video.",
                    text16: "Later, you will be able to listen or watch it again and understand or hear what you missed or didn't catch during the interview.",
                },
                archivesConsultationGuide: {
                    text1: "At the second stage (when you already know whom, when, and where to search), you can",
                    text2: "CONTACT THE ARCHIVES",
                    text3: "Each of them has a website (with an English version) and an email address. There are also sample request forms on the websites, but in most cases, they are in Ukrainian.",
                    text4: "So",
                    text5: "translate.google",
                    text6: "will come in handy.",
                    text7: "Unfortunately, archives do not conduct searches based on incomplete data.",
                    text8: "The main reason is the impossibility of such a search or the lack of the necessary number of specialists.",
                    text9: "However, there are cases when, even with all the available data, you may receive a negative response. Why is that? The reasons vary. Usually, it’s due to incomplete or poor preservation of the archives. It could also be a mistake by the specialist (the so-called human factor) or the records may be stored in a different archive.",
                    text10: "LIST OF ARCHIVES",
                },
                researchPreparationTips: {
                    text1: "Therefore, it is advisable to first conduct a preliminary independent analysis of the archive's holdings. Consult thematic forums. Work with guides and descriptions.",
                    text2: "Recently, independent searches for scanned documents have been gaining increasing popularity. Many of them are already available on",
                    text3: "familysearch.org",
                    text4: "or on the websites of the archives themselves. However, this process has only recently begun, it has its interruptions and obstacles, and it will take some more time. Therefore, not everything that exists in the archives is currently scanned and available to the public.",
                    text5: "Specific recommendations for searching pertain to those who were",
                    text6: "adopted",
                    text7: "or lived in",
                    text8: "an orphanage.",
                    text9: "In these cases, it is advisable to follow the 'documentary chain': documents related to naturalization, adoption, and birth. There may be obstacles in the form of the so-called adoption secrecy, but that is work for lawyers, attorneys, or even private detectives.",
                },
            },
            DNAGenealogyPage: {
                DNAIntro: {
                    text1: "DNA GENEALOGY",
                    text2: "Usually, it serves as an alternative, supplementary direction in genealogical research. However, sometimes DNA testing becomes the only tool.",
                    text3: "For example, when it comes to adoption",
                    text4: "or living in an orphanage.",
                    text5: "dna genealogy",
                },
                researchBenefits: {
                    text1: "HOW CAN THE",
                    text2: "«HISTORY OF GENERATIONS»",
                    text3: "RESOURCE HELP IN THIS CASE?",
                    text4: "If you know the surnames of your biological parents, you will be able to determine their areas of origin. In the second stage, you can find relevant surname holders on social networks and arrange for DNA testing with them.",
                    text5: "Or, if, for example, it concerns a medical-genetic study, plan the sampling and geography.",
                    text6: "THE ONLY THING TO REMEMBER:",
                    text7: "autosomal DNA testing will be effective only up to a 'depth' of 8 generations (a little more in endogamous ethnic groups). Greater depth is provided by Big Y (Y-DNA) and mtDNA tests. However, Big Y only traces the male line (son, father, grandfather), while mtDNA only traces the female line (daughter, mother, grandmother).",
                },
                researchDesignOptions: {
                    text1: "And in the case of extensive, complex studies,",
                    text2: "the «History of Generations»",
                    text3: "resource can become a valuable tool.",
                    text4: "In this case, the study design may be as follows:",
                    text5: "identifying the main areas of origin of the surname;",
                    text6: "conditionally mass DNA testing of surname holders from different areas using affordable test kits;",
                    text7: "identifying holders with the greatest matches;",
                    text8: "testing selected holders in a more informative manner (Big Y, mtDNA).",
                    text9: "There are many studies and publications on this topic.",
                    text10: "Including my laboratory study ",
                    text11: "«Analysis of DNA Matching Using Methods of Experimental Biology»",
                },
            },
            surnameMapPage: {
                surnameMapSection: {
                    legend: {
                        indicator1: "From 1 to 10",
                        indicator2: "From 11 to 20",
                        indicator3: "From 21 to 50",
                        indicator4: "51 and above",
                    },
                    xlsxDownloadText: "You can download the result",
                    xlsxTitle: "Download the xlsx file",
                    xlsxFields: {
                        regionUk: "Region (ukr)",
                        regionEn: "Region (eng)",
                        districtUk: "District (ukr)",
                        districtEn: "District (eng)",
                        localityUk: "Locality (ukr)",
                        localityEn: "Locality (eng)",
                        fathersCount: "Distribution of the father's surname",
                        mothersCount: "Distribution of the mother's surname",
                    },
                    spreadFathersLastName:
                        "Distribution of the father's surname",
                    spreadMothersLastName:
                        "Distribution of the mother's surname",
                    lastNameFathers: "Father's surname",
                    lastNameMothers: "Mother's surname",
                    search: "SEARCH",
                    surnameMapTable: {
                        number: "№",
                        district: "District",
                        locality: "Settlement",
                        A: "A",
                        B: "B",
                    },
                    text1: "NAME OF THE ALLOCATED DISTRICT",
                    text2: "A) The number of people with the father's surname",
                    text3: "B) The number of people with the mother's surname",
                    text4: "Surname map",
                    text5: "designed in such a way that you can search by one surname as well as by two simultaneously.",
                    text6: "WHY IS IT IMPORTANT, HOW DOES IT WORK, AND WHAT ADVANTAGES DOES IT PROVIDE?",
                    text7: "Let me give an example. My grandfather Stadnychuk was married to my grandmother Kozoriz. If you search separately, the Stadnychuks were born in 19 populated places, while Kozoriz was found in 130. A combined search (using both surnames simultaneously) gives only three options.",
                    text8: "So searching for two surnames simultaneously reduces the number",
                    text9: "of options by 6 to 43 times!",
                    text10: "When it comes to searching for a person or family, we are talking about individuals who were born at a specific time and in a specific place. But what should you do when the known locality cannot be found? There may be several options:",
                    text11: "the name you know may not be correct;",
                    text12: "the locality has been renamed;",
                    text13: "the locality no longer exists (for example, it has become part of a neighboring city).",
                    text14: "In any case, after you receive the search results by surname, you will also get a list of localities where individuals with that surname were born. It may happen that you immediately recognize your locality. Or you may need to check all of them, and for example, in a Wikipedia article, you might find a familiar but outdated (no longer in use) name for that locality.",
                    text15: "Or in the stories and legends, it was mentioned that your ancestral nest 'was near...' or 'they walked to...'. In this case, you will need to check what is located near those localities that you received as a result of the surname search.",
                    text16: "Surname of men on the paternal side",
                    text17: "Surname of men on the maternal side",
                },
                contributionSection: {
                    text1: "BECOME A PART OF THE PROJECT!",
                    text2: "First and foremost, support the History of Generations project financially.",
                    text3: "Bank name: ",
                    text4: "Address: ",
                    text5: "IBAN: ",
                    text6: "SWIFT: ",
                    text7: "Account holder: ",
                    text8: "Payment purpose: ",
                    text9: "Support for the History of Generations project",
                    text10: "PayPal: ",
                    text11: "PrivatBank Card: ",
                },
            },
            archivesPage: {
                type: {
                    null: "Select type of archives",
                    [ArchivesTypes.REGIONAL]: "Regional",
                    [ArchivesTypes.CENTRAL]: "Central",
                    [ArchivesTypes.SECTORAL]: "Sectoral",
                },
                archivesIntro: {
                    text1: "Archives",
                    text2: "The main genealogical information is stored in",
                    text3: "27 regional, 7 central",
                    text4: "and",
                    text5: "15 sectoral",
                    text6: "state archives.",
                    text7: "Sometimes, you can find something in museums, private collections, or even at auctions. However, such cases are sporadic and not systematic.",
                    text8: "However, searches should start specifically with the state regional archives.",
                    text9: "archives",
                },
                central: "Central State Archives",
                regional: "Regional State Archives",
                sectoral: "Sectoral State Archives",
            },
            contactsPage: {
                contactsIntro: {
                    text1: "MY NAME IS",
                    text2: "VOLODYMYR STADNYCHUK",
                    text3: "I have been in this world for 50 years (information as of 2024 😊)",
                    text4: "In the distant year of 1999, I graduated from one of the Ukrainian universities with a degree in 'Biology, Physiology.' Nine years of study and six years of laboratory practice. I was involved in one of the areas of experimental biology.",
                    text5: "I have been fascinated by",
                    text6: "genealogy",
                    text7: "for almost",
                    text8: "20 years",
                    text9: "I started my searches for myself. Now, mostly, I help others. I believe that in this way, too,",
                    text10: "we can make the world a better place",
                    text11: "contacts",
                },
                contactsSendEmail: {
                    text1: "YOU CAN CONTACT ME IN THE FOLLOWING WAY:",
                    text2: "Your name:",
                    text3: "Your Email:",
                    text4: "Message:",
                    text5: "SEND",
                    successMessage: "Message sent successfully",
                },
            },
            admin: {
                login: {
                    title: "Login",
                    action: "Log in",
                    email: "Email adress",
                    password: "Password",
                },
                sidebar: {
                    menu: "Menu",
                    expand: "Expand Menu",
                    residentsPage: "List of People",
                    archivesPage: "Archives",
                    districtsPage: "Districts",
                    settingsPage: "Settings",
                    logOut: "Logout",
                },
                common: {
                    create: "Create New Record",
                    update: "Update",
                    delete: "Delete",
                    deleteText: "Are you sure you want to delete this record?",
                    filters: "Filters",
                    filtersReset: "Clear filters",
                    apply: "Apply",
                    cancel: "Cancel",
                    ok: "OK",
                },
                residentsPage: {
                    tableColumns: {
                        firstName: "Name",
                        lastName: "Surname",
                        country: "Country",
                        region: "Region",
                        districtName: "District",
                        locality: "Settlement",

                        firstNameUk: "Name (Ukrainian)",
                        lastNameUk: "Surname (Ukrainian)",
                        countryUk: "Country (Ukrainian)",
                        regionUk: "Region (Ukrainian)",
                        districtNameUk: "District (Ukrainian)",
                        localityUk: "Settlement (Ukrainian)",

                        firstNameEn: "Name (English)",
                        lastNameEn: "Surname (English)",
                        countryEn: "Country (English)",
                        regionEn: "Region (English)",
                        districtNameEn: "District (English)",
                        localityEn: "Settlement (English)",
                    },
                },
                archivesPage: {
                    tableColumns: {
                        name: "Name",
                        address: "Address",
                        phoneStr: "Phone",
                        emailStr: "Email",
                        site: "Website",
                        type: "Type",

                        nameUk: "Name (Ukrainian)",
                        addressUk: "Address (Ukrainian)",

                        nameEn: "Name (English)",
                        addressEn: "Address (English)",
                    },
                    creationModal: {
                        addPhone: "Add phone",
                        addEmail: "Add Email",
                    },
                },
                districtsPage: {
                    tableColumns: {
                        district: "District name",
                        districtUk: "District name (Ukrainian)",
                        districtEn: "District name (English)",
                        geo: "Coordinates in GeoJSON format",
                        geoStr: "Coordinates",
                    },
                },
                settingsPage: {
                    changePassword: {
                        changePasswordTitle:
                            "Change the administrator password",
                        currentPassword: "Current password",
                        newPassword: "New password",
                        confirmNewPassword: "Confirm new password",
                        buttonTitle: "Change password",
                        successTitle1: "Password successfully changed.",
                        successTitle2:
                            "Now you need to log in to your account.",
                    },
                },
            },
        },
    },
};
