import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cookie from "react-cookies";
import { translations } from "../translations";
import { BehaviorSubject } from "rxjs";
import { Langs } from "../domain/enums/common/LangsEnum";
import localStorageServiceFactory from "./LocalStorageServiceImpl";
import { LanguageService } from "../domain/services/LanguageService";
import { PaypalLangs } from "../integrations/paypal/domain/PaypalLangs";
import { setGlobalCookies } from "../Config";

export const LANGS = [Langs.UA, Langs.EN];
const DEFAULT_LANGUAGE = Langs.UA;

export class LanguageServiceImpl implements LanguageService {
    private constructor() {
        this._currentLang =
            localStorageServiceFactory().getLang() || DEFAULT_LANGUAGE;
    }

    private static _instance: LanguageService;
    private _currentLang: Langs = DEFAULT_LANGUAGE;
    private _langs: Langs[] = LANGS;

    static lang$ = new BehaviorSubject(DEFAULT_LANGUAGE);

    public static getInstance(): LanguageService {
        if (!LanguageServiceImpl._instance) {
            LanguageServiceImpl._instance = new LanguageServiceImpl();
        }
        return LanguageServiceImpl._instance;
    }

    initLanguages(): void {
        const saved = this.loadFromCookie();
        i18n.use(initReactI18next).init({
            resources: translations,
            lng: this.currentLang || saved,
            keySeparator: ".",
        });
        if (!saved) {
            this.saveToCookie(this.currentLang);
        }
    }

    get currentLang(): Langs {
        return this._currentLang;
    }

    get langs(): Langs[] {
        return this._langs.filter((lang) => lang !== this.currentLang);
    }

    private saveToCookie(value: Langs) {
        const options = !setGlobalCookies
            ? {}
            : { domain: window.location.hostname };
        cookie.save("lang", value as string, options);
    }

    private loadFromCookie(): Langs {
        return cookie.load("lang");
    }

    setCurrentLang(value: Langs): void {
        this._currentLang = value;
        i18n.changeLanguage(this._currentLang);
        this.saveToCookie(value);
        localStorageServiceFactory().setLang(value as Langs);
    }

    notifyListeners(lang: Langs): void {
        LanguageServiceImpl.lang$.next(lang);
    }

    isCurrentLang(lang: Langs): boolean {
        return lang === this.currentLang;
    }

    getCurrentPhoneNumberLocale(): any {
        switch (this.currentLang) {
            case Langs.EN:
                return require(`react-phone-number-input/locale/en.json`);
            case Langs.UA:
                return require(`react-phone-number-input/locale/ua.json`);
            default:
                return require(`react-phone-number-input/locale/en.json`);
        }
    }

    get isUkrainian(): boolean {
        return this._currentLang === Langs.UA;
    }

    get isEnglish(): boolean {
        return this._currentLang === Langs.EN;
    }

    get currentPaypalLang(): PaypalLangs {
        switch (this.currentLang) {
            case Langs.UA:
                return PaypalLangs.UA;
            case Langs.EN:
                return PaypalLangs.EN;
            default:
                return null;
        }
    }
}

export default function languageServiceFactory(): LanguageService {
    return LanguageServiceImpl.getInstance();
}
