import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomFormik from "../../../custom-hooks/useCustomFormik";
import { donateFormFields, donateFormSchemaFactory } from "../../../validation-schemas/DonateFormSchema";
import DonatePaypalModal from "./DonatePaypalModal";
import DonateSuccessModal from "./DonateSuccessModal";

interface DonateFormProps {
    className?: string;
    buttonElementClassName?: string;
}

const DonateForm: FC<DonateFormProps> = (props) => {
    const [isDonateModalVisible, setDonateModalVisibility] = useState(false);
    const [isSuccessModalVisible, setSuccessModalVisibility] = useState(false);
    const { t } = useTranslation();
    const { form } = useCustomFormik({
        initial: null,
        fields: donateFormFields,
        schema: donateFormSchemaFactory(t),
        onSubmit: async (_values, _handlers) => {}, // void because handling from PayPalCheckoutButton
    });

    return (
        <div className={props.className}>
            <DonatePaypalModal
                isVisible={isDonateModalVisible}
                form={form}
                setVisibility={setDonateModalVisibility}
                setSuccessModalVisibility={setSuccessModalVisibility}
                buttonElementClassName={props.buttonElementClassName}
            />
            <DonateSuccessModal isVisible={isSuccessModalVisible} setVisibility={setSuccessModalVisibility} />
        </div>
    );
};

export default DonateForm;
