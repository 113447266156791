import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomFormik from "../../custom-hooks/useCustomFormik";
import { CustomerSupportEmailDTO } from "../../domain/models/dto/CustomerSupportDTO";
import {
    customerSupportEmailFields,
    customerSupportEmailSchemaFactory,
} from "../../validation-schemas/CustomerSupportEmailSchema";
import CustomInput from "../shared/form-inputs/CustomInput";
import { InputType } from "../../types/InputType";
import CustomTextArea from "../shared/form-inputs/CustomTextArea";
import customerSupportServiceFactory from "../../services/CustomerSupportServiceImpl";
import CommonError from "../shared/form-inputs/CommonError";
import Toast from "../shared/Toast";
import useToastMessage from "../../custom-hooks/useToastMessage";
import { ResponseMessage } from "../../domain/enums/common/ResponseMessageEnum";
import languageServiceFactory from "../../services/LanguageServiceImpl";

const customerSupportService = customerSupportServiceFactory();
const languageService = languageServiceFactory();

const ContactsSendEmail: FC = () => {
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { message, setMessage, resetMessage } = useToastMessage();

    const { form } = useCustomFormik<CustomerSupportEmailDTO>({
        initial: null,
        fields: customerSupportEmailFields,
        schema: customerSupportEmailSchemaFactory(t),
        onSubmit: async (values, helpers) => {
            setError("");
            setLoading(true);
            const result = await customerSupportService.sendEmail(values, languageService.currentLang);
            if (!result) {
                return setError("errors.common");
            }
            setLoading(false);
            setMessage("contactsPage.contactsSendEmail.successMessage", ResponseMessage.SUCCESS);
            helpers.resetForm();
        },
    });

    return (
        <div className="relative bg-beige4 shadow-beige4Section h-[960px]">
            <div className="absolute left-0 right-0 m-auto w-fit flex items-center h-full">
                <img
                    className="absolute right-[850px] top-[45px]"
                    src="/img/contacts-page/contactsSendEmail.png"
                    alt="Contacts Send Email"
                />
                <div className="w-[748px] ml-[563px]">
                    <p className="font-cormorant text-[45px] leading-[54.5px] text-brown3 mb-[39px]">
                        {t("contactsPage.contactsSendEmail.text1")}
                    </p>
                    <div className="flex mb-[55px]">
                        <div className="flex flex-col">
                            <CustomInput
                                labelKey={"contactsPage.contactsSendEmail.text2"}
                                labelClassName="font-manrope !font-normal text-[22px] leading-[32px] text-brown3 mb-0"
                                type={customerSupportEmailFields.clientName.type as InputType}
                                value={form.values.clientName}
                                name="clientName"
                                placeholderKey={customerSupportEmailFields.clientName.placeholderKey}
                                errorKey={form.errors.clientName as string}
                                onChange={form.handleChange}
                                className="!bg-beige4 font-manrope !text-brown3 border-l-0 border-t-0 border-r-0 border-b rounded-none !h-[40px] w-[360px] border-brown3 shadow-none focus:outline-none focus:border-l-transparent focus:border-t-transparent focus:border-r-transparent focus:border-b-brown3 focus:ring-0 mr-[28px]"
                            />
                        </div>
                        <div className="flex flex-col">
                            <CustomInput
                                labelKey={"contactsPage.contactsSendEmail.text3"}
                                labelClassName="font-manrope !font-normal text-[22px] leading-[32px] text-brown3 mb-0"
                                type={customerSupportEmailFields.clientEmail.type as InputType}
                                value={form.values.clientEmail}
                                name="clientEmail"
                                placeholderKey={customerSupportEmailFields.clientEmail.placeholderKey}
                                errorKey={form.errors.clientEmail as string}
                                onChange={form.handleChange}
                                className="!bg-beige4 font-manrope !text-brown3 border-l-0 border-t-0 border-r-0 border-b rounded-none !h-[40px] w-[360px] border-brown3 shadow-none focus:outline-none focus:border-l-transparent focus:border-t-transparent focus:border-r-transparent focus:border-b-brown3 focus:ring-0 mr-[28px]"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col mb-[55px]">
                        <CustomTextArea
                            labelKey={"contactsPage.contactsSendEmail.text4"}
                            labelClassName="font-manrope !font-normal text-[22px] leading-[32px] text-brown3 mb-[17px]"
                            value={form.values.message}
                            name="message"
                            placeholderKey={customerSupportEmailFields.message.placeholderKey}
                            errorKey={form.errors.message as string}
                            onChange={form.handleChange}
                            className="!bg-beige4 font-manrope !text-brown3 border rounded-[29px] !h-[226px] w-full border-brown3 shadow-none focus:outline-none focus:border-b-brown3 focus:ring-0 px-[30px] py-[20px]"
                        />
                    </div>
                    <div className="relative">
                        <button
                            type="button"
                            className={`bg-button-gradient w-[269px] h-[76px] font-semibold py-2.5 px-6 rounded-full ${
                                loading ? "opacity-40" : ""
                            }`}
                            onClick={() => form.handleSubmit()}
                            disabled={loading}
                        >
                            <div className="flex justify-center items-center">
                                <p className="font-cormorant leading-[34px] text-[26px] bg-clip-text text-[transparent] bg-button-text-gradient">
                                    {t("contactsPage.contactsSendEmail.text5")}
                                </p>
                            </div>
                        </button>
                        <Toast
                            messageObj={message}
                            resetMessageObj={resetMessage}
                            wrapClassName="!bg-beige4"
                            successIconWrapClassName="!bg-beige4"
                            dangerIconWrapClassName="!bg-beige4"
                            warningIconWrapClassName="!bg-beige4"
                            closeButtonClassName="!bg-beige4 !text-brown3"
                            labelClassName="font-manrope text-brown3"
                            staticClassName="top-[87px] left-[25px] !w-[200px]"
                            staticType
                            singleMessageMode
                        />
                    </div>
                    <CommonError errorKey={error} />
                </div>
            </div>
        </div>
    );
};

export default ContactsSendEmail;
