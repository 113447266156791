import { FC } from "react";
import { useTranslation } from "react-i18next";
import DonateForm from "./DonateForm";

const DonateBlock: FC = () => {
    const { t } = useTranslation();

    return (
        <div className="absolute left-0 right-0 top-[137px] mx-auto h-[65px] bg-beige4 flex justify-center items-center">
            <p className="font-manrope text-[16px] text-brown3 leading-[23.2px]">
                {t("donateForm.actionCallText")}
                <span className="font-[700]">{t("donateForm.projectName")}</span>
            </p>
            <DonateForm />
        </div>
    );
};

export default DonateBlock;
