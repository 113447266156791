export const environment = {
  production: false,
  'API_URL': 'https://api.historygenerations.life',
  'PROJECT_EMAIL': 'info@historygenerations.life',
  'PROJECT_FACEBOOK': 'https://www.facebook.com/groups/1036211147399554',
  'AUTOCOMPLETE_PATTERN_MIN': '3',
  'ACCOUNT_OWNER': 'Volodymyr Stadnychuk',
  'PAYPAL': 'stadnychuk.volodymyr@gmail.com',
  'PRIVATBANK_CARD': '5168 7450 3216 2270',
  'USE_CUSTOM_TRANSFORM': 'true',
  'PAYPAL_CLIENT_ID': 'ASNTCQKD_5UJbnlRLY5Opf3GL8okiRsK_me2lQXBPt6apGhy75Rl-Ah6L76H2LxvB3deKF3ZSSa7aMtA',
  'PAYPAL_DISABLE': 'false',
  'SET_GLOBAL_COOKIES': 'true'
};
